<template>
  <div class="admin min-h-screen">
    <router-view />

    <user-modal />
    <cancel-call-modal />
  </div>
</template>

<script>
import UserModal from '@/components/modals/UserModal';
import CancelCallModal from '@/components/modals/CancelCallModal';

export default {
  name: "Admin",
  components: {
    UserModal,
    CancelCallModal,
  },
};
</script>
