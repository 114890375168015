<template>
  <div class="cancel-call-modal fixed top-0 left-0 flex items-center justify-center w-full h-full opacity-0">
    <span
      class="cancel-call-modal-layer fixed top-0 left-0 w-full h-full bg-black opacity-0 cursor-pointer"
      @click="setCancelCallModal(false)"
    ></span>

    <div class="cancel-call-modal__card bg-white p-10 opacity-0 rounded-lg">
      <h3 class="text-blue font-semibold text-xl mb-8">Você tem certeza de que deseja cancelar esse atendimento?</h3>

      <div class="cancel-call-modall__infos flex items-center mb-8">
        <div class="cancel-call-modall__infos-data h-16 p-2 px-3 text-blue">
          <h5 class="text-lg">{{ date.format('ddd DD/MM') }}</h5>
          <p class="text-xs">{{ date.format('H:mm') }} até {{ endDate.format('H:mm') }}</p>
        </div>

        <div class="cancel-call-modal__infos-user flex items-center justify-center p-2 px-3 h-16">
          <avatar class="mr-3" :image="user.avatar" :name="user.name" />
          <h4 class="text-xs text-blue">
            {{ user.name }}
          </h4>
        </div>
      </div>

      <p class="text-sm opacity-50 text-black italic">
        Ao cancelar seu atendimento, dois e-mails de notificação serão enviados.
        Um e-mail para o time NIVEA e outro e-mail para {{ user.name }}.
        Os dois e-mails têm a missão de comunicar sobre o cancelamento e de oferecer auxílio para que {{ user.first_name }} faça um novo agendamento de sua consulta.
      </p>

      <div class="cancel-call-modal__actions flex items-center justify-between mt-10">
        <Button light ghost @click="setCancelCallModal(false)" class="mr-8">Voltar</Button>
        <Button @click="cancel">Sim, cancelar</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import dayjs from 'dayjs';
import api from '@/utils/api.js';

import Button from "@/components/Button";
import Avatar from '@/components/Avatar';

export default {
  name: "CancelCallModal",
  components: { Avatar, Button },
  computed: {
    ...mapState(['userModal']),
    user() {
      return this.userModal.user;
    },
    reservation() {
      return this.userModal.reservation;
    },
    date() {
      return dayjs(this.reservation.date);
    },
    endDate() {
      return dayjs(this.reservation.end_date);
    }
  },
  methods: {
    ...mapMutations(['setCancelCallModal', 'closeUserModal']),

    async cancel() {
      await api.put(`/reservations/${this.reservation.id}/cancel`);
      this.$noty.success('A consulta foi cancelada.');
      this.setCancelCallModal(false);
      this.closeUserModal();
      this.$router.push({ name: 'Calendar' }).catch(() => {});
    }
  },
};
</script>

<style lang="scss">
.has-cancel-call-modal {
  .cancel-call-modal {
    opacity: 1;
    pointer-events: auto;
  }

  .cancel-call-modal-layer {
    @apply opacity-30;
  }

  .cancel-call-modal__card {
    opacity: 1;
    transform: none;
  }
}

.cancel-call-modal {
  z-index: 100;
  bottom: 0;
  pointer-events: none;
  overflow-y: scroll;
  transition: opacity 0.3s, transform .3s;
}

.cancel-call-modal-layer {
  z-index: -1;
}

.cancel-call-modal__card {
  width: 540px;
  transform: scale(0.90);
  transition: transform .3s, opacity 0.3s;
}

.cancel-call-modall__infos-data,
.cancel-call-modal__infos-user {
  background-color: rgba(81, 103, 207, 0.1);
  border: 1px solid rgba(0, 19, 111, 0.1);
}

.cancel-call-modal__infos-user {
  max-width: 170px;
}
</style>
